.main-body-container {
    background-color: white;
    margin: 0;
}

.main-banner {
    text-align: left;
    position: relative;
    /* border: 1px solid black; */
    margin: 0;
    text-align: center;
  
}

.main-banner img {
    width: 100%;
    height: 90vh;
}


.main-banner h1 {
    font-family: 'Josefin Sans', sans-serif;
    color: #52b788;
    font-size: 4.5rem;
    font-weight: 700;
    text-align: left;
    left: 10%;

    position: absolute;
    width: 60%;
    bottom: 30%;
}
