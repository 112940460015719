.navbar.navbar-top {
    /* margin: 0 10%; */
    background-color: white;
    display: flex;
    height: 100px;
    /* border-bottom: 1px solid rgb(139, 137, 137); */
    justify-content: space-between;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.logo {
    cursor: pointer;
    padding: 0;
    margin: 0 30px;
}

.logo img {
    width: 120px;
}

.nav-link {
    font-size: 0.85rem;
}

.navbar-links {
    margin: 0;
    padding: 0;
    border-radius: 8px;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
}
.dropdown-menu {
    width: 200px;
}

.nav-item {
    margin: 0 7px;
    padding: 3px;
}

.nav-item.dropstart {
    margin-right: 60px;
}

.nav-item.dropstart span {
    font-size: 1.5rem;
}



