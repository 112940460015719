.contact-form-container {
    display: flex;
    justify-content: space-between;
    margin: 50px 120px 100px 120px;
}

.contact-banner {
  margin-left: 20px;
}

.contact-banner img {
    width: 90%;
}