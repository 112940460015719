.service-main-container {
    height: 100%;
}

.service-banner {
    width: 100%;
}

.service-banner img {
    width: 100%;
}

.servives-container {
    margin: 10px 0 60px 100px ;
    display: flex;
    justify-content: center;
}

.services-side-text {
    font-size: 1.2rem;
    margin: 100px 20px 0 0;
    width: 35%;
}

.services {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 70%;
}

.servi-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
}

.service-box {
    margin: 0 10px 10px 1px;
    background-color: rgb(252, 252, 252);
    width: 230px;
    max-height: 500px;
}

.service-box h1 {
    background-color: #52b788;
    color: aliceblue;
    padding: 10px;
    font-size: 1.4rem;
}

.service-box div {
    width: 100%;
    height: 100px;
}

.service-box ul li{
    list-style: disc;
    padding-left: 0;
}



.line {
    width: 2px;
    margin-top: 70px;
    background-color: rgba(0, 0, 0, 0.185);
    height: 400px;
}