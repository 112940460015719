.solution-main-container {
    display: flex;
}


.solution-banner {
    width: 100%;
    text-align: center;
}

.solution-banner img {
    margin-top: 50px;
    width: 90%;
}

.solutions-text {
    width: 30%;
    margin: 100px 70px 100px 100px;
}


.solutions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
}

.solutions {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
}

.boxx {
    display: flex;
    flex-direction: column;
    align-items:center ;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.171);
    margin: 10px;
    width: 250px;
    height: 40px;
}

.boxx h1 {
    font-size: 1rem;
}

